import React from "react";
import companyLogo1 from "../../vendors/images/l2.png";

const JCS = () => {
    return (
        <div>
            <div>
                <div className="container company">
                    <div className="row pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
                            <div className="d-flex mt-2">
                                <h1 style={{ minWidth: '80%' }}>JCS MACHINERY</h1>
                                <div style={{ minWidth: '20%' }} className="d-block">
                                    <img src={companyLogo1} alt="JCS MACHINERY logo" style={{ width: "100%" }} />
                                </div>
                            </div>

                            <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-3 mt-md-5 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                                JCS Machinery being one of the oldest among our group of companies hold a very positive reputation for our
                                work ethic; that being, the quality of the goods we sell and our after-sales service. This extends from local to
                                international. We have been able to earn a good reputation among our suppliers from all over the world due to
                                our perseverance and consistency with our work.
                                <br /><br />
                                We import all kinds of construction machinery, outboard motors and engines and any kind of equipment
                                requested by our clients. Our main supplier base is from Europe and Japan, which ensures that the goods we
                                import and supply to our clients are of good quality and durability.
                                <br /><br />
                                JCS Machinery is the agent in Sri Lanka for Benza products from Spain, namely lawn mowers, generators,
                                vacuum cleaners, water high pressure cleaners, etc, and the distributor of Mercury outboard motors, Hyundai
                                marine diesel engines (used on fishing boats) and Hamilton Jet used on all of the Sri Lankan navyjet boats.
              </div>
                        </div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JCS;