import React from "react";
import companyLogo1 from "../../vendors/images/l5.png";
import { useEffect, useState } from "react";
import { environment } from "../../environment/environment";
import { Card } from "react-bootstrap";
import usedTag from "../../vendors/images/card_new_green.png";
import { RouteNames } from "../../RouteNames";
import { Link, useNavigate } from "react-router-dom";

const Newgen = () => {
    const history = useNavigate();
    const [products, setProducts] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetch(`${environment.api_url}/product/getSubCategoryProducts/category/6/brand/9/subcategory/16`)
            .then((response) => response.json())
            .then((data) => {
            setProducts(data);
            setIsLoading(false);
            });
    }, []);

    const getProductsView = () => {
        return products.map((item: any, i: number) => (
            <div key={i} className="col-md-6 col-sm-6 col-lg-4 col-xl-3 col-12 mb-3 d-flex justify-content-center products">
                <Card onClick={() => selectProduct(item)} style={{ width: "18rem" }} className="shadow prod-list-card">
                    <img src={usedTag} loading="lazy" alt="used" className="used-tag" />
                    <div className="card-bg">
                        <Card.Img variant="top" src={`${environment.img_url}/${item.imageUrl}`} />
                    </div>
                    <Card.Body className="d-flex justify-content-center align-items-center">
                        <Card.Title className="text-center title">{item.name}</Card.Title>
                    </Card.Body>
                </Card>
            </div>
        ));
    };

    const selectProduct = (item: any) => {
        history(RouteNames.PRODUCTDETAILS + `/${item.id}/Medicine/Newgen Lifescience`);
    };

    return (
        <div>
            <div>
                <div className="container company">
                    {/* <h1>NEWGEN LIFESCIENCE (PVT) LTD</h1>
                    <div className="row mt-3">{getProductsView()}</div> */}
                    <div className="row pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
                            <div className="d-flex mt-2">
                                <h1 style={{ minWidth: '80%' }}>NEWGEN LIFESCIENCE (PVT) LTD</h1>
                                <div style={{ minWidth: '20%' }} className="d-block">
                                    <img src={companyLogo1} alt="NEWGEN LIFESCIENCE logo" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="d-block mt-3">
                            <Link to={RouteNames.PRODUCT_LIST+`/6/9/Pharmaceuticals/Newgen Lifescience`}>Go To Products</Link>
                            </div>
                            <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-4 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                                We are one of the leading importers and distributors of pharmaceuticals, surgical and diagnostic equipment and food supplement products for the local market.
                                Our contribution in the field has already helped to shape the health care of people by making available quality
                                products from European producers. In addition to providing aspects of health improvements to the lives of
                                patients throughout the country, we also provide our overseas principals to the best of our services.
                                <br /><br />
                                As the agent for Novelty Pharma of Switzerland, Redwing British Labs of England and Westcoast
                                Pharmaceuticals, Orion Lifescience, both of India, Newgen Lifescience (Pvt) Ltd. has established itself with a
                                substantial percentage of the market and reached the top spot as one ofthe leading importers and distributors
                                of pharmaceuticals, surgical and diagnostic equipment and food supplement products locally.
              </div>
                        </div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newgen;
