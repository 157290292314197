import sakura from "../../vendors/images/sakura.png";

const Services = () => {
    return (
        <div>
            <div className="container-fluid company-banner pb-3">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="logo-bg">
                                    <img src={sakura}></img>
                                </div>
                            </div>
                            <div className="col-12">
                                <h1>
                                    Services & Spares
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-8 align-items-center justify-content-center d-flex services">
                        <div className="intro">
                            <p className="p-4">
                            We import and supply original spare parts and accessories of laundry equipment of brands Speed Queen, Primus, Electrolux, Italclean, Grandimpianti and IPSO upon your request with possibilities of warranty. 
                  <br /><br />
                                
                                With our reputed history in the field of imports to Sri Lanka, we can assure to deliver you genuine products at all times. We pride ourselves on genuine, high quality equipment and parts at consistently competitive prices with fast delivery and reliable after-sales support to give you extra peace of mind. Please get in touch with us for any of your domestic or commercial needs for laundry equipment or spare parts.
                </p>
                            {/* <div className="pt-5 visionCont">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="vm">
                                            <h2 className="d-flex align-items-center justify-content-center">Our Vision</h2>
                                            <div className="d-flex align-items-center justify-content-center missiondiv">
                                                <p>Our vision is to be the leading supplier of construction machinery, laundry equipment, generators and pharmaceuticals in the country to cater the needs of our clients thereby contributing to the community and the development of the country.
          </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mission">
                                        <div className="vm">
                                            <h2 className="d-flex align-items-center justify-content-center">Our Mission</h2>
                                            <div className="align-items-center d-flex justify-content-center missiondiv">
                                                <p>Our mission is to establish excellent relationships with our clients, our suppliers and our employees by pursuing business in the world of imports and exports, building a bridge between Sri Lankan and other countries in international trade.
          </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;