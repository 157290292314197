import React from "react";
import companyLogo1 from "../../vendors/images/l7.png";

const Matsuda = () => {
    return (
        <div>
            <div>
                <div className="container company">
                    <div className="row pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
                            <div className="d-flex mt-2">
                                <h1 style={{ minWidth: '80%' }}>Matsuda Kogyo GK</h1>
                                <div style={{ minWidth: '20%' }} className="d-block">
                                    <img src={companyLogo1} alt="Matsuda Kogyo GK logo" style={{ width: "100%" }} />
                                </div>
                            </div>

                            <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-3 mt-md-5 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                                We act as a bridge in trade between Sri Lanka and Japan, importing and exporting goods and commodities to and from Sri Lanka.
                                With our offices located in Osaka, Japan, we are involved in the service and entertainment industry as well. Matsuda Kogyo GK has its own restaurant and karaoke bars located in Osaka. We stand out with a touch of Sri Lanka in Osaka when people are in search of an escape from their busy schedules and tiring day-to-today activities, especially after the COVID-19 breakout.
                        </div>
                        </div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Matsuda;