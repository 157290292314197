import React from "react";
import sakura from "../../vendors/images/sakura.png";
import "../../vendors/styles/about.css";

const About = () => {
  return (
    <div>
      <div className="container-fluid company-banner pb-3">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="row">
              <div className="col-12">
                <div className="logo-bg">
                  <img src={sakura}></img>
                </div>
              </div>
              <div className="col-12">
                <h1>
                  About Us
                    </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-8">
            <div className="intro">
              <p className="p-4">
                Nearly a quarter century ago, Rukkaththana Tea Factory formulated in Galle in 1996 was involved in the tea industry before it diversified into importing construction equipment and machinery. Trade changed with the formation of JC Sakura International Company founded in the year 2002. Today JC Sakura International Group of Companies stand high as a specialist in commercial appliances and as the leading supplier of commercial laundry equipment including washing machines, dryers and dry cleaning machines from reputed manufacturers. Thus, JC Sakura International Group of Companies, one of the leading names in business in Sri Lanka, Japan and all over Europe, is a one-stop-shop for the entire range of superior laundry solutions, construction machinery, metal working machines, wood working machines, generators, cleaning equipment and pharmaceuticals.
                  <br />
                <br />
                Creating a name for itself, the company rapidly reached the status of a leading industrial machinery supplier and solutions provider, gradually expanding to the international zone from subsidiaries: JCS Machinery, JCN Machinery in the Netherlands, Tellustec Co. Ltd in Tokyo, Japan, micro-tunnelling technology masters: Tokyo Pipe Engineering (Pvt) Ltd, Matsuda Kogyo GK involved in import/ export and the entertainment venue sector in Osaka, Japan and the pharmaceutical products importer and distributor Newgen Lifescience (Pvt) Ltd.
                  <br />
                <br />
                The management of the group consists of visionaries with experience in the field under whom highly qualified and experienced personnel including engineers, maintenance technicians and support staff perform operations and together the team has spearheaded over 600 projects country wide.
                </p>
              <div className="pt-5 visionCont">
                <div className="row">
                  <div className="col-12">
                    <div className="vm">
                      <h2 className="d-flex align-items-center justify-content-center">Our Vision</h2>
                      <div className="d-flex align-items-center justify-content-center missiondiv">
                        <p>Our vision is to be the leading supplier of construction machinery, laundry equipment, generators and pharmaceuticals in the country to cater the needs of our clients thereby contributing to the community and the development of the country.
          </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mission">
                    <div className="vm">
                      <h2 className="d-flex align-items-center justify-content-center">Our Mission</h2>
                      <div className="align-items-center d-flex justify-content-center missiondiv">
                        <p>Our mission is to establish excellent relationships with our clients, our suppliers and our employees by pursuing business in the world of imports and exports, building a bridge between Sri Lankan and other countries in international trade.
          </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row visionTitle">
        <h2>Our Vision</h2>
      </div>
      <div className="container about">
        <div className="row">
          <div className="col-sm-12 pt-5 pb-3">
            <div className="intro p-4 mt-4 mr-1 ml-1" style={{ marginRight: '0px' }}>
              <p>
                Our vision is to be the leading supplier of construction machinery, laundry equipment, generators and pharmaceuticals in the country to cater the needs of our clients thereby contributing to the community and the development of the country.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row missionTitle">
        <h2>Our Mission</h2>
      </div>
      <div className="container about">
        <div className="row">
          <div className="col-sm-12 pt-5 pb-3">
            <div className="intro p-4 mt-4 mr-1 ml-1" style={{ marginRight: '0px' }}>
              <p>
              Our mission is to establish excellent relationships with our clients, our suppliers and our employees by pursuing business in the world of imports and exports, building a bridge between Sri Lankan and other countries in international trade.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default About;
