import React from "react";
import companyLogo1 from "../../vendors/images/l3.png";

const TokyoPipe = () => {
    return (
        <div>
            <div>
                <div className="container company">
                    <div className="row pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
                            <div className="d-flex mt-2">
                                <h1 style={{minWidth: '80%'}}>TOKYO PIPE ENGINEERING (PVT) LTD</h1>
                                <div style={{minWidth: '20%'}} className="d-block">
                                    <img src={companyLogo1} alt="TOKYO PIPE ENGINEERING logo" style={{ width: "100%" }} />
                                </div>
                            </div>

                            <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-3 mt-md-5 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                                Tokyo Pipe Engineering is our company in Sri Lanka representing
                                Tellustec company based in Japan that mainly undertakes
                                microtunelling projects in Sri Lanka.We are determined to introduce
                                and provide sustainable innovations to our beautiful country with
                                the support and influence of its Japanese shareholders.
                                <br /><br />
                                We are currently working on a project of cultivating Kenaf which is a
                                plant that absorbs a high percentage of carbondioxide. The fully
                                grown Kenaf plant can either be exported or be used to produce a
                                source of lubricant which is eco-friendly and thus not harmful to the
                                environment.The remaining pulp can be used to produce paper.The
                                main goal of this project is to help the agricultural economy of Sri
                                Lanka with creating jobs directly and indirectly and to enhance the
                                inflow of foreign currency to the country.
                                <br /><br />
                                Our team is constantly working on bringing foreign knowledge that
                                is sustainable into the country using all opportunities we are
                                available with.
              </div>
                        </div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TokyoPipe;
