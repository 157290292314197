import '../../vendors/styles/footer.css';

const Footer = () => {

  const getdate = () => {
    return new Date().getFullYear();
  };

  return (
      <div className='footer-container'>
        <div className="address">
          <h4>JC Sakura International Group of Companies</h4>
          <h5>No. 335/3B, Moratuwa Road,<br/>
          Piliyandala, Sri Lanka.<br/>
          +94 112 616 919 | +94 773 779 997</h5>
          <p className="footer-text m-0">&copy;{getdate()} All rights reserved | Designed by <a href="mailto:dinnotechnologies@gmail.com"><u>Dinno</u></a></p>
        </div>
      </div>
  )
}

export default Footer