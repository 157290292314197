import { NavLink, useNavigate } from 'react-router-dom'
import { RouteNames } from '../../RouteNames'
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../../vendors/styles/nav.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//import  Switch from "react-router-dom";
import React from "react";

import { Row, Col, NavDropdown } from "react-bootstrap";

import "react-bootstrap-submenu/dist/index.css";

const Navigation = (props:any) => {
  const history = useNavigate();
  const categories = props.category;

  const navigate = (item: any) => {
    if (item.id == 7) {
      history(RouteNames.PRODUCT_LIST+`/7/12/Used Machinery/JC Sakura`);
      history(0);
    } else {
      history(RouteNames.BRANDS+`/${item.name}/${item.id}`);
    }
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="nav">
      <Navbar.Brand href=""></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <div className="spacer"></div>
        <Nav className="ml-auto" >
          <Nav.Link eventKey="1" style={{padding: 0}}>
            <NavLink className="nav-link" to={RouteNames.ROOT}>Home</NavLink>
          </Nav.Link>
          <Nav.Link eventKey="3" style={{padding: 0}}>
            <NavLink className="nav-link" to={RouteNames.ABOUT}>About Us</NavLink>
          </Nav.Link>
          <NavDropdown title="Products" id="collasible-nav-dropdown">  
            {categories.map((item:any, i:number)=>(
              <Nav.Link eventKey={i} key={i}>
                <NavDropdown.Item onClick={() => navigate(item)} className="nav-item">{item.name}</NavDropdown.Item>
              </Nav.Link>
                  ))}
          </NavDropdown>
          <Nav.Link eventKey="2" style={{padding: 0}}>
            <NavLink className="nav-link" to={RouteNames.SERVICES}>Services</NavLink>
          </Nav.Link>
          <Nav.Link eventKey="4" style={{padding: 0}}>
            <NavLink className="nav-link" to={RouteNames.CONTACTUS}>Contact</NavLink>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
  </Navbar>
  )
}

export default Navigation