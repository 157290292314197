import React from "react";
import companyLogo1 from "../../vendors/images/l4.png";

const Tellustec = () => {
    return (
        <div>
            <div>
                <div className="container company">
                    <div className="row pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
                            <div className="d-flex mt-2">
                                <h1 style={{minWidth: '80%'}}>TELLUSTEC CO. LTD</h1>
                                <div style={{minWidth: '20%'}} className="d-block">
                                    <img src={companyLogo1} alt="TELLUSTEC logo" style={{ width: "100%" }} />
                                </div>
                            </div>

                            <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-3 mt-md-5 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                                We are the leading micro tunneling engineering organization
                                contributing to the development of micro engineering work,
                                general civil engineering work and ground improvement work.
                                <br/><br/>
                                Being based in Japan, we are dedicated to providing the best
                                engineering and technological support and service requested from
                                us by aligning with the values and principles ofour parent company.
                                Our engineers, micro-tunnelling operators and civil workers are
                                highly experienced Japanese professionals who can ensure that our
                                clients receive the best services and solutions in all projects we
                                undertake with the promise of duly completion of the tasks.
                                <br/><br/>
                                Our mission is to establish excellent relationships with the state and public by pursuing business through innovation and advanced
                                technology.
              </div>
                        </div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tellustec;
