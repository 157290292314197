import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { environment } from "../../environment/environment";
import "../../vendors/styles/brands.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "../../RouteNames";

const Brands = () => {
  const [brands, setBrands] = useState<any>();

  const { cat_name, cat_id } = useParams();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  // const search = useLocation().search;
  // const currentCategoryQueryParms = new URLSearchParams(search).get("category");


  useEffect(() => {
    fetch(`${environment.api_url}/product/getCategoryBrands/${cat_id}`)
      .then(response => response.json())
      .then(data => {
        setBrands(data);
        setIsLoading(false);
      });
    // let catWithBrands = Promise.all(
    //   categories.map((category: any) => {
    //     return fetch(`${environment.api_url}/product/getCategoryBrands/${category.id}`)
    //       .then((response) => response.json())
    //       .then((data) => {
    //         return {
    //           ...category,
    //           brands: data,
    //         };
    //       });
    //   })
    // );

    // catWithBrands.then((data) => {
    //   setCat(data);
    //   setIsLoading(false);
    // });

  }, [cat_id]);
  // const index = categories.findIndex((object: any) => {
  //   return object.name == currentCategoryQueryParms;
  // });
  // if (index != 0) {
  //   let tempCategories = categories
  //   let temp = tempCategories[index]
  //   tempCategories[index] = tempCategories[0]
  //   tempCategories[0] = temp
  //   setCat(tempCategories)
  // }
  const selectBrand = (brand: any) => {
    history(RouteNames.PRODUCT_LIST + `/${cat_id}/${brand.id}/${cat_name}/${brand.name}`);
  };

  const getBrandsView = (brands: any) => {
    return brands.map((brand: any, i: any) => (
      <div key={i} className="col-md-3 col-sm-6 col-12 mb-3 d-flex justify-content-center">
        <Card onClick={() => selectBrand(brand)} style={{ width: "18rem" }} className="brands-card">
          <div className="card-bg">
            <Card.Img variant="top" src={require(`./../../vendors/logos/${brand.name}.png`)} />
          </div>
          <Card.Body className="d-flex justify-content-center align-items-center">
            <Card.Title className="text-center title">{brand.name}</Card.Title>
          </Card.Body>
        </Card>
      </div>
    ));
  };

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-12">
          <div>
            <div className="mt-5" id={cat_name}>
              <div className="brand-strip">{cat_name} </div>
            </div>

            <div className="brands">
              <div className="mt-4">
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <h5 className="fa-fade">Loading...</h5>
                  </div>
                ) : (
                  <div className="row mt-3">{getBrandsView(brands)}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
